import React from 'react'
import {graphql, Link} from 'gatsby'
import Img, {FluidObject} from 'gatsby-image'
import styled, {css} from 'styled-components'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock} from '@fortawesome/free-solid-svg-icons'

import {useAuth, useUser, useAmplitude} from 'hooks'
import {MainLayout} from 'layouts'
import {SEO, AviQuote, LinkButton, Testimonials, DrawstrokeHighlight} from 'components'
import {LockSVG, PlaySVG, Triangles, LetsDoThis, StartForFree} from 'images/svgs'

const IntroSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-bottom: 25px;
  width: 100%;

  background-color: var(--white);

  @media only screen and (min-width: 700px) {
    max-height: calc(500px + 10vw);
  }

  @media only screen and (min-width: 1200px) {
    max-height: 600px;
  }
`

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);

  @media only screen and (min-width: 700px) {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const TitleDiv = styled.div`
  position: relative;
  margin-top: 35px;

  h1 {
    margin-top: 50px;
  }

  p {
    margin-bottom: 25px;
  }

  @media only screen and (min-width: 700px) {
    max-width: 600px;
    margin-left: 5vw;
  }
`

const ProFlag = styled.div`
  position: absolute;
  display: inline-block;

  padding: 5px 25px 5px 10px;

  background-color: var(--green);
  color: white;
  font-weight: 600;
  font-family: var(--primary-font);

  clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
`

const DetailsCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  border-radius: 10px;
  width: 100%;
  padding: 25px;
  margin: 25px 0;

  background-color: var(--white);
  filter: var(--shadow);

  @media only screen and (min-width: 700px) {
    width: calc(300px + 10vw);
    max-width: 400px;
  }
`

const TopicThumbnail = styled(Img)<{fluid: FluidObject}>`
  width: 100%;
  padding-top: 56.36%;
`

const TopicDetail = styled.div`
  margin: 25px 0;

  h4 {
    margin: 10px 0;
  }

  p {
    margin: 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`

const tag = css`
  display: inline-block;

  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 15px;

  font-family: var(--primary-font);
  font-weight: 600;
`

const TechTag = styled.div`
  ${tag}
  background-color: var(--violet);
`

const StyledTriangles = styled(Triangles)`
  position: absolute;
  bottom: 0;
  left: calc((100vw - var(--mobile-width)) / 2);
  transform: translateY(25%);

  width: 200px;
  height: 50px;

  @media only screen and (min-width: 700px) {
    left: unset;
    right: calc((100vw - var(--mobile-width)) / 2);
  }

  @media only screen and (min-width: 1334px) {
    right: calc((100vw - var(--content-width)) / 2);
  }
`

const TopicSection = styled.div`
  display: flex;
  flex-direction: column;

  width: var(--mobile-width);
  margin: 25px 0;

  @media only screen and (min-width: 1200px) {
    max-width: var(--content-width);
  }
`

const lessonCardStyle = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 10px;
  margin-bottom: 25px;

  background-color: var(--white);

  h4 {
    margin-top: 0;
  }

  @media only screen and (min-width: 700px) {
    flex-direction: row;
    transition: var(--transition);
  }
`

const LessonCardNoClick = styled.div`
  ${lessonCardStyle}
`

const LessonCard = styled(Link)`
  ${lessonCardStyle}

  @media only screen and (min-width: 700px) {
    transition: var(--transition);

    &:hover {
      filter: var(--shadow);
    }
  }
`

const LessonDetails = styled.div`
  padding: 25px;
`

const Duration = styled.p`
  color: var(--grey);
  font-size: 0.9em;
`

const ThumbnailContainer = styled.div`
  display: none;

  svg {
    display: none;
  }

  @media only screen and (min-width: 700px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      display: unset;
      position: absolute;
      z-index: 1;
      height: 60%;
    }
  }
`

const thumbnailStyle = css`
  display: none;

  @media only screen and (min-width: 700px) {
    display: flex;
    flex-shrink: 0;
    width: 300px;
    height: 100%;
  }
`

const ThumbnailNormal = styled(Img)<{fluid: FluidObject}>`
  ${thumbnailStyle}
`

const ThumbnailLocked = styled(Img)<{fluid: FluidObject}>`
  ${thumbnailStyle}

  @media only screen and (min-width: 700px) {
    filter: brightness(0.5);
  }
`

const ThumbnailPlay = styled(Img)<{fluid: FluidObject}>`
  ${thumbnailStyle}

  @media only screen and (min-width: 700px) {
    filter: brightness(0.75);
  }
`

const ThumbnailEmpty = styled.div<{color?: string}>`
  ${thumbnailStyle}

  @media only screen and (min-width: 700px) {
    background-color: ${({color}) => color ?? 'var(--black)'};
  }
`

const GetStartedSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-bottom: 50px;

  background-color: var(--white);

  h2 {
    font-weight: 600;
  }
`

const GetStartedContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  h2 {
    font-weight: 600;
  }
`

const WrittenWordLeft = styled(StartForFree)`
  display: none;

  @media only screen and (min-width: 700px) {
    position: absolute;
    display: unset;

    width: 200px;

    left: 0;
    transform: translateX(-100%);
  }
`

const WrittenWordRight = styled(LetsDoThis)`
  display: none;

  @media only screen and (min-width: 700px) {
    position: absolute;
    display: unset;

    width: 200px;

    right: 0;
    transform: translateX(100%);
  }
`

const TopicTasks = ({topic, lessons}: {topic: StrapiTopic; lessons: StrapiLessons}) => {
  const {user} = useUser()
  const {logEvent} = useAmplitude()

  const logClick = (buttonName, data = {}) =>
    logEvent('topic-view-button-click', {slug: topic.slug, buttonName, ...data})

  type ThumbnailType = 'play' | 'lock' | 'normal'
  const Thumbnail = ({type, fluid, ...props}: {type: ThumbnailType; fluid?: FluidObject}) => {
    switch (type) {
      case 'play':
        return (
          <ThumbnailContainer>
            <PlaySVG />
            {fluid ? <ThumbnailPlay fluid={fluid} {...props} /> : <ThumbnailEmpty />}
          </ThumbnailContainer>
        )
      case 'lock':
        return (
          <ThumbnailContainer>
            <LockSVG />
            {fluid ? <ThumbnailLocked fluid={fluid} {...props} /> : <ThumbnailEmpty />}
          </ThumbnailContainer>
        )
      case 'normal':
        return fluid ? <ThumbnailNormal fluid={fluid} {...props} /> : <ThumbnailEmpty color='#0E0E0E' />
    }
  }

  const truncateDescription = (description: string) => {
    const shortString = description.substr(0, 200)

    if (shortString !== description) {
      return `${shortString}...`
    }

    return description
  }

  interface CardTemplateProps {
    lesson: TopicLesson
    thumbnailFluidObject?: FluidObject
    thumbnailType: ThumbnailType
    cardComponent: React.FC<{to: string; onClick?: () => void}> | React.FC
    link?: string
  }

  const CardTemplate = ({
    lesson,
    thumbnailFluidObject,
    thumbnailType,
    cardComponent: CardComponent,
    link,
  }: CardTemplateProps) => (
    <CardComponent
      key={lesson.slug}
      to={link ?? ''}
      onClick={link ? () => logClick('lesson-preview', {lessonSlug: lesson.slug}) : undefined}
    >
      <Thumbnail type={thumbnailType} fluid={thumbnailFluidObject} />
      <LessonDetails>
        <h4>{lesson.title}</h4>
        <p>{truncateDescription(lesson.description)}</p>
        <Duration>
          <FontAwesomeIcon icon={faClock} style={{marginRight: '.4em'}} />
          {Math.ceil(lesson.video.duration / 60)} min
        </Duration>
      </LessonDetails>
    </CardComponent>
  )

  if (!user) {
    return (
      <TopicSection>
        <h2>Lessons</h2>
        {topic.lessons.map((lesson, i) => (
          <CardTemplate
            key={i}
            lesson={lesson}
            thumbnailType='normal'
            thumbnailFluidObject={
              lessons.find((node) => node.slug === lesson.slug)?.gatsbyThumbnail.childImageSharp.fluid
            }
            cardComponent={LessonCardNoClick}
          />
        ))}
      </TopicSection>
    )
  }

  return (
    <TopicSection>
      <h2>Lessons</h2>
      {topic.lessons.map((lesson, i) => (
        <CardTemplate
          key={i}
          lesson={lesson}
          thumbnailType={user.proStatus === 'inactive' && !lesson.free ? 'lock' : 'play'}
          thumbnailFluidObject={
            lessons.find((node) => node.slug === lesson.slug)?.gatsbyThumbnail.childImageSharp.fluid
          }
          cardComponent={user.proStatus === 'inactive' && !lesson.free ? LessonCardNoClick : LessonCard}
          link={
            user.proStatus === 'inactive' && !lesson.free
              ? undefined
              : `/app/learn?contentType=topic&slug=${topic.slug}&task=${i + 1}`
          }
        />
      ))}
    </TopicSection>
  )
}

const TopicPage = (props: {data: TopicDetailsQuery}) => {
  const {user} = useAuth()
  const {user: userData, topics} = useUser()
  const {logEvent} = useAmplitude()
  const {strapiTopic, allStrapiLesson} = props.data

  const logClick = (buttonName, data = {}) =>
    logEvent('topic-view-button-click', {slug: strapiTopic.slug, buttonName, ...data})

  const StartButton = () => {
    if (user) {
      if (userData?.proStatus === 'inactive' && !strapiTopic.free) {
        return (
          <LinkButton
            tier='primary'
            buttoncolor='var(--green)'
            activecolor='var(--green-active)'
            to={`/pricing`}
            onClick={() => logClick('to-pricing')}
          >
            Become a Creator
          </LinkButton>
        )
      } else {
        const currentTopicObject = topics?.find((p) => p.slug === strapiTopic.slug)

        if (currentTopicObject && currentTopicObject.watchedLectures.length >= strapiTopic.lessons.length) {
          return (
            <LinkButton
              tier='primary'
              to={`/app/learn?contentType=topic&slug=${strapiTopic.slug}&task=0`}
              onClick={() => logClick('again')}
            >
              Learn Again
            </LinkButton>
          )
        } else if (currentTopicObject && currentTopicObject.watchedLectures.length !== 0) {
          return (
            <LinkButton
              tier='primary'
              to={`/app/learn?contentType=topic&slug=${strapiTopic.slug}`}
              onClick={() => logClick('continue')}
            >
              Continue Learning
            </LinkButton>
          )
        } else {
          return (
            <LinkButton
              tier='primary'
              to={`/app/learn?contentType=topic&slug=${strapiTopic.slug}`}
              onClick={() => logClick('start')}
            >
              Start Learning
            </LinkButton>
          )
        }
      }
    } else {
      return (
        <LinkButton
          tier='primary'
          to='/app/get-started'
          state={{successUrl: `/app/learn?contentType=topic&slug=${strapiTopic.slug}`}}
          onClick={() => logClick('get-started')}
        >
          Get Started
        </LinkButton>
      )
    }
  }

  return (
    <MainLayout>
      <SEO
        title={strapiTopic.title}
        description={strapiTopic.description}
        image={strapiTopic.gatsbyThumbnail.publicURL}
      />
      <IntroSection>
        <IntroContainer>
          <TitleDiv>
            {!strapiTopic.free && <ProFlag>CREATOR ONLY</ProFlag>}
            <h1>{strapiTopic.title}</h1>
            <p>{strapiTopic.description}</p>
            <StartButton />
          </TitleDiv>
          <DetailsCard>
            <TopicThumbnail
              alt={`${strapiTopic.title} thumbnail`}
              fluid={strapiTopic.gatsbyThumbnail.childImageSharp.fluid}
            />
            <div>
              {strapiTopic.categories && strapiTopic.categories.length !== 0 && (
                <TopicDetail>
                  <h4>TECHNOLOGIES</h4>
                  <Row>
                    {strapiTopic.categories.map((category, i) => (
                      <TechTag key={i}>{category.tag}</TechTag>
                    ))}
                  </Row>
                </TopicDetail>
              )}
              <TopicDetail>
                <h4>WATCH TIME</h4>
                <p>
                  {Math.ceil(strapiTopic.lessons.reduce((sum, lesson) => sum + lesson.video.duration, 0) / 60)} minutes
                </p>
              </TopicDetail>
            </div>
          </DetailsCard>
        </IntroContainer>
        <StyledTriangles />
      </IntroSection>
      <TopicTasks topic={strapiTopic} lessons={allStrapiLesson.nodes} />
      {user === null && (
        <>
          <AviQuote />
          <Testimonials single />
          <GetStartedSection>
            <GetStartedContent>
              <WrittenWordLeft />
              <h2>
                Ready to dive in?
                <br />
                Start learning <DrawstrokeHighlight type='underline'>today</DrawstrokeHighlight>.
              </h2>
              <LinkButton tier='primary' to='/app/get-started' onClick={() => logClick('get-started-bottom')}>
                Get Started
              </LinkButton>
              <WrittenWordRight />
            </GetStartedContent>
          </GetStartedSection>
        </>
      )}
    </MainLayout>
  )
}

export default TopicPage

type GatsbyImageSharpFluid = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type TopicLesson = {
  free: boolean
  slug: string
  title: string
  description: string
  video: {
    duration: number
  }
}

type StrapiTopic = {
  slug: string
  title: string
  description: string
  free: boolean
  categories: Array<{
    tag
  }>
  gatsbyThumbnail: GatsbyImageSharpFluid & {publicURL: string}
  lessons: Array<TopicLesson>
  introduction: {
    duration: number
  }
}

type StrapiLessons = Array<{
  slug: string
  gatsbyThumbnail: GatsbyImageSharpFluid
}>

interface TopicDetailsQuery {
  strapiTopic: StrapiTopic
  allStrapiLesson: {nodes: StrapiLessons}
}

export const TopicDetails = graphql`
  query TopicDetails($slug: String, $lessonSlugs: [String]) {
    strapiTopic(slug: {eq: $slug}) {
      slug
      description
      title
      free
      categories {
        tag
      }
      gatsbyThumbnail {
        childImageSharp {
          fluid {
            src
          }
        }
      }
      lessons {
        free
        slug
        title
        description
        video {
          duration
        }
      }
    }
    allStrapiLesson(filter: {slug: {in: $lessonSlugs}}) {
      nodes {
        slug
        gatsbyThumbnail {
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
